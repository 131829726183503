// @flow
import React from "react";
import { css } from "aphrodite";
import { Images, AppStyles } from "../../../theme";
import styles from "./TeamSecStyles";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { Button } from "react-bootstrap";
import { OurTeamSection } from "../../../components";

export default function TeamSecView(props) {
  const { teamMembersData } = props;
  return (
    <section
      className={`py-5 ${css([
        // styles.marginBottom,
        styles.TeamSec,
        // AppStyles.pxy_12,
      ])}`}
    >
      <div className={`container`}>
        <div className={css(styles.teamContainer)}>
          <div className={`mt-5 ${css(styles.teamHead)}`}>Meet The Team</div>

          <div className={css(styles.cardContent)}>
            Healthcity’s core team and its associates include qualified
            psychologists, therapists, nurses, doctors and other professionals,
            as well as, former service users. They have relevant training,
            skills, and experiences culturally and professionally.Below just
            some of our team members. Behind the experienced directors and
            managers below stand a number of awesome, dedicated non-executive
            directors and advisors, clinicians, University researchers and
            client support staff who make us all look good and achieving.
          </div>
        </div>
        <div className={css(styles.teamSectionView)}>
          <OurTeamSection isShortView={true} />
        </div>
        <div className={css(styles.teamBtnCont)} style={{ marginTop: "-40px" }}>
          <NavLink to={ROUTES.TEAM}>
            <Button className={css(styles.teamBtn)}>See All Team</Button>
          </NavLink>
        </div>
        <p className={css(styles.pSec)}>
          Struggling with Mental Health? Let's Talk
        </p>
        <p className={css(styles.pSecTwo)}>Frequently Asked Questions</p>
        <p className={css(styles.faqContent)}>
          <p style={{fontWeight: "bold"}}>
            1. What is a clinical psychologist? </p> <br />
          A clinical psychologist specializes in diagnosing and treating mental
          health disorders through various therapeutic techniques, providing
          support for emotional and psychological challenges. <br />
          <p style={{fontWeight: "bold"}}> <br />
          2. How do I know if I need to see a clinical psychologist?
          </p>
          <br />
          If you experience persistent feelings of anxiety, depression, or
          emotional distress that interfere with daily life, consulting a
          clinical psychologist can help you navigate these challenges. <br />
          <p style={{fontWeight: "bold"}}> <br />
          3. What types of therapies do clinical psychologists use? </p>
          <br />
          Clinical psychologists employ various evidence-based therapies,
          including Cognitive Behavioral Therapy (CBT), Dialectical Behavior
          Therapy (DBT), and mindfulness practices tailored to individual needs.
          <p style={{fontWeight: "bold"}}> <br />
          4. Are clinical psychologists qualified to prescribe medication?
          </p>
          <br />
          Generally, clinical psychologists do not prescribe medication.
          However, they may work closely with psychiatrists or other medical
          professionals who can provide medication when necessary.
          <p style={{fontWeight: "bold"}}>
          <br />
          5. How long does therapy with a clinical psychologist typically last?{" "}
          </p>
          <br />
          The duration of therapy varies based on individual needs, treatment
          goals, and progress. Sessions can range from a few weeks to several
          months or longer.
          <p style={{fontWeight: "bold"}}>
          <br />
          6. Is therapy with a private clinical psychologist confidential?{" "}
          </p>
          <br />
          Yes, therapy sessions are confidential. Clinical psychologists adhere
          to strict ethical guidelines, ensuring your privacy and creating a
          safe space for open discussions. <br />
          <p style={{fontWeight: "bold"}}> <br />
          7. What should I expect during my first appointment with a clinical
          psychologist? </p>
          <br />
          During your first appointment, the clinical psychologist will
          typically conduct an assessment, discuss your concerns, and develop a
          treatment plan tailored to your needs.
        </p>
      </div>
    </section>
  );
}
