// @flow
import React from "react";
import { css } from "aphrodite";
import { Images } from "../../../theme";
import styles from "./CBTSecStyles";
import { ROUTES } from "../../../constants";
import { NavLink } from "react-router-dom";
import { Button } from "../../../components";
export default function CBTSecView(props) {
  return (
    <section
      id="cbt"
      className={`pt-5 ${css([
        // styles.marginBottom,
        styles.CBTSect,
        // AppStyles.pxy_12,
      ])}`}
    >
      <div className={`container `}>
        <div className={`container`}>
          <div className={css(styles.CBTCont)}>
            <div className={`mt-5 ${css(styles.mainHeading)}`}>
              Clinical Psychology at Health City
            </div>
            <div className={css(styles.exHeading)}>
              What Support Our Clinical, Counselling and Health Psychologists
              offer you?
            </div>

            <div className={css(styles.exContent)}>
              Most psychologists are trained at doctor level to help you talk,
              process your feelings, gain a deep understanding, and acquire
              practical ways or techniques to cope with almost any difficulty to
              resolve your issues or achieve resilience to thrive in your life
              from relationships to business and career. Most psychologists may
              help you by using more than one set of treatment skills, e.g.
              Cognitive behavioural tehrapy (CBT), eye movement desensitisation
              reprocessing therapy (EMDR), counselling, coaching, etc.
            </div>

            <div className={css(styles.CBTInnerCont)}>
              <div className={css(styles.CBTTextCont)}>
                <div className={css(styles.CBTHeading)}>
                  {/* WE HELP TO PLAN AND SUPPORT STAFF TO */}
                  Qualified, Accredited, Registered Clinical Psychologists
                </div>
                <p className={css(styles.addContent)}>
                  Having a qualified and registered clinical psychologist
                  ensures you receive expert care tailored to your mental health
                  needs. These professionals are trained to understand complex
                  emotional and psychological issues, offering evidence-based
                  therapies. With their expertise, you can trust that your
                  treatment is safe, effective, and designed to foster long-term
                  mental well-being and positive life changes.
                </p>
                <p className={css(styles.pHead)}>
                  Why Trust Us With Your Mental Health Issues?
                </p>
                <p
                  style={{
                    lineHeight: 1.8,
                    fontSize: 18,
                  }}
                >
                  <li>Qualified and Experienced Professionals</li>
                  <li>Confidential and Safe Support</li>
                  <li>Comprehensive Approach</li>
                  <li>Online & In-person Sessions</li>{" "}
                </p>
              </div>
              <div className={css(styles.CBTInerContTwo)}>
                <img src={Images.cbt_imgOne} className={css(styles.cbtImg)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
