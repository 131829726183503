// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Fonts, Images } from "../../../theme";

export default StyleSheet.create({
  CBTSect: {
    width: "100%",
    height: "100%",
    paddingTop: "50px",
    background: Colors.background.secondary,
    "@media (max-width: 991px)": {
      marginTop: "30px",
      paddingTop: "10px",
    },
    "@media (max-width: 991px)": {
      marginTop: "50px",
      padding: "30px 0",
    },
  },

  CBTInnerCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    "@media (max-width: 1199px)": {
      flexDirection: "column",
    },
  },

  CBTTextCont: {
    width: 480,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "@media (max-width: 1199px)": {
      width: "100%",
    },
  },
  CBTHeading: {
    fontSize: 30,
    lineHeight: 1.3,
    fontWeight: 600,
    color: Colors.text.primary,
  },
  mainHeading: {
    textAlign: "center",
    fontSize: 46,
    lineHeight: 1.3,
    fontWeight: 600,
    marginBottom: 8,
    color: Colors.text.primary,
  },
  exHeading: {
    fontSize: 22,
    lineHeight: 1.3,
    fontWeight: 450,
    textAlign: "center",
    color: Colors.text.primary,
  },
  exContent: {
    lineHeight: 1.44,
    marginTop: 20,
    width: "100%",
    marginBottom: 60,
  },
  cbtImg: {
    width: 526,
    marginBottom: 53,
    "@media (max-width: 1199px)": {
      marginTop: 30,
      marginBottom: 0,
      width: "100%",
    },
  },
  addContent: {
    lineHeight: 1.44,
    marginTop: 20,
    width: "100%",
    marginBottom: 20,
  },

  pHead: {
    lineHeight: 1.44,
    fontWeight: 450,
    fontSize: 22,
    marginBottom: 10,
    marginTop: 30,
  },
  CBTHead: {
    fontWeight: "bold",
    fontSize: 56,
    textAlign: "center",
    color: Colors.text.primary,
    lineHeight: 1.5,
  },
  teamSectionView: {
    marginTop: 100,
    "@media (max-width: 1199px)": {
      marginTop: 50,
    },
    "@media (max-width: 992px)": {
      margin: "50px auto 0",
    },
  },
  CBTInerContTwo: {
    width: 550,
    lineHeight: 1.47,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 1199px)": {
      width: "100%",
      flexDirection: "column-reverse",
      marginTop: 50,
    },
  },
  CBTBtnCont: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  CBTBtn: {
    width: 183,
    height: 55,
    background: Colors.background.green,
    borderRadius: 1,
    border: "transparent",
    color: Colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 600,
    textTransform: "uppercase",
    "@media (max-width: 991px)": {
      margin: "20px 0 0",
    },
    "@media (max-width: 600px)": {
      width: 150,
      fontSize: 14,
      height: 40,
    },
  },
});
